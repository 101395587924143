import { Controller } from 'stimulus'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded';

export default class extends Controller {
  connect() {
    this.initializeMasonry();
    imagesLoaded(this.element).on('progress', () => {
      this.masonry.layout();
    });
  }

  initializeMasonry() {
    this.masonry = new Masonry(this.element, {
      itemSelector: '.item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      transitionDuration: 0,
      stagger: 30,
      isResizeBound: false
    });
  }
}
