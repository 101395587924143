import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["submit"];

    onSubmit(event) {
        event.preventDefault();

        const disabledHtml = this.data.get("loadingHtml");
        const loadingSpinner = this.element.querySelector(".loading-spinner");

        this.submitTarget.setAttribute("disabled", "disabled");
        this.submitTarget.style.display = "none";

        loadingSpinner.innerHTML = disabledHtml;
        loadingSpinner.style.display = "inline-block";

        // Submit the form after modifying the button
        event.target.closest("form").submit();
    }
}