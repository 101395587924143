import consumer from "./consumer"

consumer.subscriptions.create({ channel: "FeedChannel"}, {
  received(data) {
    if (data.reply_to > 0)
      $("#message-replies-" + data.reply_to.toString()).append(data.body);
    else
      $("[data-messages-id='" + data.email_group_id + "']").append(data.body);
//    $("[data-messages-id='" + data.email_group_id + "']").not(".discussion-for-" + data.email_user_id).append(data.body);
    $("#wall-panel-" + data.email_group_id).scrollTop($("#wall-panel-" + data.email_group_id).prop('scrollHeight'));
    $('.lazy').lazy();
  }
});
