import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-settings"

export default class extends Controller {
  navigateToUrl(event) {
    const url = this.element.getAttribute("data-url");
    if (url) {
      window.location.href = url;
    }
  }
}