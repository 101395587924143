import consumer from "./consumer"

consumer.subscriptions.create({ channel: "MessageChannel"}, {
  received(data) {
    $("[data-messages-id='" + data.email_group_id + "']").not(".discussion-for-" + data.email_user_id).append(data.body);
    $("[data-messages-id='" + data.email_group_id + "']").scrollTop($("[data-messages-id='" + data.email_group_id + "']").prop('scrollHeight'));
    if ($('.discussion-summary.active').data('discussion-summary') != data.email_group_id) {
      $('#tab-'+ data.email_group_id).remove();
      for (let i=0;i<data.recipient_list.length;i++) {
        $('.discussion-summaries-'+data.recipient_level+'-'+data.recipient_list[i]).prepend(data.summary);
      }
    }
    $('.lazy').lazy();
  }
});