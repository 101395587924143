import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log('arrived here')
    this.fetchQrCodeData();
  }

  async fetchQrCodeData() {
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const attendable = pathSegments[2]
    const eventId = pathSegments[3];

  }
}
