import $ from 'jquery';

export const multiselectInput = () => {

  function format (option) {
      if (!option.id) { return option.text; }
      option.img = $(option.element).attr('data-imgProfile');
      var ob = `<div class='d-flex align-items-center'><div class='avatar-small me-2 background-image' style="background-image:url(${option.img})"></div> ${option.text}</div>`;
      return ob;
    }
  
  function matchStart(params, data) {
    params.term = params.term || '';
    if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) >= 0) {
        return data;
    }
    return false;
  }

  $("#myval").select2({
      placeholder: "Search contact here",
      width: "100%",
      allowClear: true,
      matcher: function(params, data) {
        return matchStart(params, data);
      },
      templateResult: format,
      templateSelection: function (option) {
          if (option.id.length > 0 ) {
              return `<div class='single-select-box'><div class='d-inline-flex align-items-center single-select'><div class='avatar-small me-2 background-image' style="background-image:url(${option.img})"></div> ${option.text}</div><div>`;
          } else {
              return option.text;
          }
      },
      escapeMarkup: function (m) {
        return m;
      }
  });

};
