  export const stepByStep = () => {
    //  select all the steps
    const stepList = document.querySelectorAll(".sbs > li");
    //  select all the tabs related to it
    const tabList = document.querySelectorAll(".sbs-tab > .tab-pane");
    // select all the i tag and add the fa-check
    const checkElement = document.createElement("i");
    checkElement.classList.add("fa", "fa-check");
    // summary input box
    let reviewFormBox = document.querySelector('#disabled-fields-form');
    // select all the i tag and add the fa-circle
    const dotElement = document.createElement("i");
    dotElement.classList.add("fa", "fa-circle");
    
    // behaviour when the step is finished
    function setFinished(element) {
    	if (!element) return;
    	element.classList.remove("active");
    	if (!element.classList.contains("finished")) {
    		element.classList.add("finished");
    	}
    	const indicator = element.querySelector(".indicator");
    	if (indicator) {
    		indicator.innerHTML = "";
    		indicator.appendChild(checkElement.cloneNode(true));
    	}
    	setFinished(element.previousElementSibling);
    }
    // behaviour when the step is not active and finished
    function setDefault(element) {
    	if (!element) return;
    	element.classList.remove("active");
    	element.classList.remove("finished");
    	const indicator = element.querySelector(".indicator");
    	if (indicator && indicator.dataset.default) {
    		indicator.innerHTML = indicator.dataset.default;
    	} else if (indicator) {
    		indicator.innerHTML = "";
    	}
    	setDefault(element.nextElementSibling);
    }
    // function triggered on tab click
    function openTabBehaviour(element) {
    // when opening a tab - apply active class and remove finished one
		element.classList.add("active");
		element.classList.remove("finished");
		// trigger opening the tab content on active
      tabList.forEach((tab) => {
        tab.classList.remove("active","show");
        if (tab.getAttribute("id") === (element.dataset.bsTarget).substring(1)) {
          tab.classList.add("active","show");
        }
      });
    }
    
    stepList.forEach((step) => {
      
      function tabPanel(e) {
        e.preventDefault();
        const radioBtn = document.querySelectorAll('input[type="radio"]:checked');
        //  if there is not client mode choosen the user cannot proceed
        if (radioBtn.length != 0) {
    		  openTabBehaviour(step);
        }
        // behaviour of the first step when starting
  		  if (step.classList.contains("active")) {
      		if (step.parentNode.classList.contains("sbs--border")) {
      			const indicator = step.querySelector(".indicator");
      			indicator.innerHTML = indicator.dataset.default;
      		} 
      		// trigger behaviours of finished steps and next one active
      		setFinished(step.previousElementSibling);
      		setDefault(step.nextElementSibling);
        }
      }
      // tab behaviours on click
    	step.addEventListener("click", tabPanel);
      //  go next
      document.querySelector('.go-next-btn').addEventListener("click", (e) => {
        e.preventDefault();
        const radioBtn = document.querySelectorAll('.tab-pane.active input[type="radio"]:checked');
          if (radioBtn.length != 0) {
            if (step.querySelector(".indicator").dataset.default == '01') {
        		  setFinished(step);
        		  setDefault(step.nextElementSibling);
        		  openTabBehaviour(step.nextElementSibling);
            }
          }
      })
      document.querySelector('.go-next-btn-2').addEventListener("click", (e) => {
        e.preventDefault();
        const radioBtn = document.querySelectorAll('.tab-pane.active input[type="radio"]:checked');
          if (radioBtn.length != 0) {
            if (step.querySelector(".indicator").dataset.default == '02') {
        		  setFinished(step);
        		  setDefault(step.nextElementSibling);
        		  openTabBehaviour(step.nextElementSibling);
            }
          }
      })
      // go back
      document.querySelectorAll('.go-back-btn').forEach((goBack) => {
        if (step.querySelector(".indicator").dataset.default == '02' || step.querySelector(".indicator").dataset.default == '03') {
          goBack.addEventListener("click", (e) => {
            e.preventDefault();
      		  openTabBehaviour(step.previousElementSibling);
      		  setFinished(step.previousElementSibling);
      		  setDefault(step);
      		  reviewFormBox.innerHTML = ''
          })
        } 
      })
      
      // keep fourth step locked until form is submitted 	
      if (step.querySelector(".indicator").dataset.default === '04') {
        step.removeEventListener("click", tabPanel);
      }
      
      if (step.querySelector(".indicator").dataset.default == '03') {
        document.querySelector('.view-summary').addEventListener("click", (e) => {
      	  e.preventDefault();
        	const inputsContainer = document.querySelector(".inputs-add-form");
          const inputElements = inputsContainer.querySelectorAll("input");
          const selectElements = inputsContainer.querySelectorAll('select');
          const allInputElements = [ ...inputElements, ...selectElements ];
          let filledInputs = []
          
          inputElements.forEach((el) => {
            if (el.value !== "") {
              filledInputs.push(el);              
            }
          })
          
          selectElements.forEach((el) => {
            if (el.options[el.selectedIndex].value || el.value) {
             filledInputs.push(el); 
            }
          })
          
          if (allInputElements.length === filledInputs.length) {
            step.addEventListener("click", tabPanel);
            setFinished(step);
      		  setDefault(step.nextElementSibling);
      		  openTabBehaviour(step.nextElementSibling);
      		  // create the review piece
      		  let userInputForm = document.querySelector('#inner-form')
      		  let inputsArr = [...userInputForm.querySelectorAll('.style-form')];
            let userInputFormCloneArr = inputsArr.map((el) => el.cloneNode(true));
            reviewFormBox.innerHTML = ''
            reviewFormBox.append(...userInputFormCloneArr);
            
            reviewFormBox.querySelectorAll("input").forEach((el) => el.disabled = true );
            reviewFormBox.querySelectorAll("select").forEach((el) => {
              filledInputs.forEach((input) => {
                if (input.getAttribute('name') === el.getAttribute('name')) {
                  el.value = input.value
                  el.disabled = true
                }
              })
            });
          } else {
            // trigger an alert
            document.querySelector("#new-client-alert").classList.add('show');
            document.querySelector("#new-client-alert").innerHTML = 'All the fields have to be filled up before continuing.'
            setTimeout(() => { document.querySelector("#new-client-alert").classList.remove('show') }, 5000);
          }
      	});
      };
    });
  };
    