import {Controller} from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        let sidebarRefreshBtn = document.getElementById('sidebar-refresh-btn');
        sidebarRefreshBtn.classList.remove('invisible');

        const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
        let id = event.item.dataset.id

        fetch(`/sidebar_items/${id}/move`, {
            method: 'PATCH', headers: {
                'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken
            }, body: JSON.stringify({position: event.newIndex + 1})
        }).then(response => {
            if (response.ok) {
                console.log('success!');
            } else if (response.status === 404) {
                console.error('Not found');
            } else {
                console.error('There was an error making the request:', response.statusText);
            }
        }).catch(error => console.error('There was an error making the request:', error));
    };
}