import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "button"];

  connect() {
    // Event listener to close the dropdown when clicking outside
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  toggle(event) {
    event.stopPropagation();

    // Close all other open dropdown menus
    const allMenus = document.querySelectorAll('[data-controller="dropdown"]');
    allMenus.forEach((menu) => {
      if (menu !== this.element) {
        const menuTarget = menu.querySelector('[data-dropdown-target="menu"]');
        menuTarget.classList.remove('show');
      }
    });

    this.menuTarget.classList.toggle('show');
  }

  close() {
    this.menuTarget.classList.remove('show');
  }

  handleOutsideClick(event) {
    // Closes the menu if click is outside the component
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

  disconnect() {
    // Removes the event listener when the component is removed
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }
}
