import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "results"];

  connect() {
    this.resultsTarget.style.display = 'none'; // Hide results div initially
  }

  getBaseUrl() {
    let url = new URL(window.location.href);
    return url.origin;
  }

  containsHTMLSyntax(text) {
    return /<\/?[a-z][\s\S]*>/i.test(text);
  }

  highlightText(text, keyword) {
    const re = new RegExp(`(${keyword})`, 'gi');
    text = text.replace(/<p><\/p>/g, '');
    return text.replace(re, '<mark style="font-weight: 700;background-color: navajowhite">$1</mark>');
  }

  search(e) {
    e.preventDefault();
    const self = this;
    let prompt = this.inputTarget.value;

    if (prompt.length < 2) {
      this.resultsTarget.innerHTML = '';
      this.resultsTarget.style.display = 'none';
      return;
    }

    let csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenElement ? csrfTokenElement.content : '';

    const baseUrl = this.getBaseUrl();
    const url = `${baseUrl}/en/global_search?query=${prompt}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
        "Accept": "application/json"
      },
    })
      .then(response => response.json())
      .then(suggestions => {
        this.resultsTarget.innerHTML = '';

        if (Object.keys(suggestions).length === 0) {
          const noResultsElement = document.createElement('div');
          noResultsElement.classList.add('suggestion');
          noResultsElement.classList.add('no-suggestions');
          noResultsElement.innerText = 'No results found';
          this.resultsTarget.appendChild(noResultsElement);
          return;
        }

        this.resultsTarget.style.display = 'block'; // Show results div when there are search results



        Object.keys(suggestions).forEach(index_title => {
          suggestions[index_title] = suggestions[index_title].filter(suggestion =>
            !self.containsHTMLSyntax(suggestion.content)
          );

          const table = document.createElement('table');
          table.classList.add('table');
          const tbody = document.createElement('tbody');
          table.appendChild(tbody);

          const titleRow = document.createElement('tr');
          titleRow.classList.add('suggestion-index-title');

          const firstSuggestion = suggestions[index_title][0];
          titleRow.innerHTML = `<th colspan="3" style="background-color: #d2dbec;border-radius: 4px;border: 2px white;">
              <a class='px-2' style="color: black;font-weight: 400;" href="${firstSuggestion.index_link}">${index_title}</a>
              </th>`;
          tbody.appendChild(titleRow);

          if (firstSuggestion.content_field === 'manual_keyword') {
            this.resultsTarget.appendChild(table);
            return;
          }

          suggestions[index_title].forEach(suggestion => {
            const row = document.createElement('tr');
            row.classList.add('suggestion-row');

            row.innerHTML = `
              <td class="suggestion-content">
                <a class="px-2 suggestion-link" style="color: black;font-weight: 400" href="${baseUrl + suggestion.show_link}">
                ${self.highlightText(suggestion.content, prompt)}
                </a>
              </td>
              `;

            tbody.appendChild(row);
          });
          this.resultsTarget.appendChild(table);
        });
      })
      .catch(error => {
        console.error('Error:', error);
        this.resultsTarget.style.display = 'none';
        this.resultsTarget.innerHTML = '';
        const noResultsElement = document.createElement('div');
        noResultsElement.classList.add('suggestion');
        noResultsElement.innerText = 'No results found';
        this.resultsTarget.appendChild(noResultsElement);
      });
  }
}
