export const documentReady = () => {

  // Profile Skills
  $(document).on('click', '.skill-remove', function() {
    $(this).parent().remove();
  });
  $(document).on('click','#skills-list', function() {
    $('#skill-field').focus(); 
  });
  $(document).on('click','#desired-skills-list', function() {
    $('#desired-skill-field').focus(); 
  });
  $(document).on('click', '.message-remove', function() {
    $(this).parent().remove();
  });
  
  // Track Settings
  $('.match_restrict input[type=checkbox]').click(function () {
    if ($(this).prop('checked'))
      $('.match_restrict_by #'+$(this).attr('id')+'_setting').css('visibility','visible');
    else
      $('.match_restrict_by #'+$(this).attr('id')+'_setting').css('visibility','hidden');
  })

	$('.action-indicator').on('click', function() {
		modal('event-'+$(this).attr('data-event'));
	});

	$('.favourites-new-corner').delay(8000).fadeOut(800, function() {
		var indicator = $('#menu-link-favourites .for-attention-indicator');
		indicator.html('');
		$.ajax({
			type:"GET",
			url : '/en/favourites/viewed',
			async: false
		});
	});

	$('.matches-new-corner').delay(8000).fadeOut(800, function() {
		var indicator = $('#menu-link-matches .for-attention-indicator');
		indicator.html('');
		$.ajax({
			type:"GET",
			url : '/en/matches/viewed?format=match',
			async: false
		});
	});

	$(".copy-link").on('click',function(){
		var $temp = $("<input>");
		var $link = $('#'+$(this).attr('id').replace('copy','link')).attr('href');
		$("body").append($temp);
		$temp.val($link).select();
		document.execCommand("copy");
		$temp.remove();            
	});
	
	// sidebar dropdown
  $('#menu-link-mentoring-compact').on('click',function() {
		$('#menu-link-mentoring-menu').toggle();
	}); 

	$('.menu-link-mentoring-compact').on('click',function() {
		$('#'+$(this).attr('id').replace('-compact','-menu')).toggle();
	});

	// multi-select checkbox lists
	$('.multiple-checkbox-option').on('click', function() {
	if ($(this).is(':checked'))
		$(this).closest('.multiple-checkbox-row').addClass('multiple-checkbox-row-selected');
	else
		$(this).closest('.multiple-checkbox-row').removeClass('multiple-checkbox-row-selected');
	});

	$('#synchronize_check').on('click',function() {
		$('#check_relationship_counts').html('');
	});

	$('#synchronize_run').on('click',function() {
		$('#result_relationship_counts').html('');
	});

  $('.interval-video-switch').on('click', function () {
    var action = $(this).attr('id').split('-')[2];
    if ($(this).is(':checked')) {
      $('#external-url-' + action).css('display', 'none');
      $('#internal-url-' + action).css('display', 'block');
    } else {
      $('#external-url-' + action).css('display', 'block');
      $('#internal-url-' + action).css('display', 'none');
    }
  });

  var dispatchUnloadEvent = function() {
	$('.modal').modal('hide');
	$('.modal').css('display','none');
  };
  addEventListener("beforeunload", dispatchUnloadEvent)
  addEventListener("turbolinks:before-render", dispatchUnloadEvent)

};