import $ from 'jquery';
 
export const sidebarExpand = () => {
  const sideBar = document.querySelector('.sidebar-expand');
  const sideBarTrigger = document.querySelector('.trigger-sidebar');
  
  if (sideBar&&sideBarTrigger) {
    
    sideBarTrigger.addEventListener('click', () => {
      sideBar.classList.toggle('on-click-sidebar');
      document.querySelector('#trigger-sidebar-icon').classList.toggle('fa-chevron-circle-right');
      document.querySelector('#trigger-sidebar-icon').classList.toggle('fa-chevron-circle-left');
    });
    
    
    document.addEventListener('click', function(event) {
      var isClickInsideElement = sideBar.contains(event.target);
      // console.log(event.target)
      // console.log(isClickInsideElement)
      if (document.querySelector('.avatar-opening-sidebar').contains(event.target)) {
        
        $('#trigger-sidebar-icon').addClass('fa-chevron-circle-left');
        document.querySelector('#trigger-sidebar-icon').classList.remove('fa-chevron-circle-right');
        sideBar.classList.add('on-click-sidebar');
      }
      else if ( (!isClickInsideElement) && sideBar.classList.contains('on-click-sidebar') ) {
        // console.log('outside')
        event.preventDefault()
        event.stopPropagation()
        sideBar.classList.remove('on-click-sidebar');
        $('#trigger-sidebar-icon').addClass('fa-chevron-circle-right');
        document.querySelector('#trigger-sidebar-icon').classList.remove('fa-chevron-circle-left');
      };
    });
  };
};
