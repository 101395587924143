import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "groupCheckbox", "selectAll", "form", "wrapper"]

    connect() {
        const referrerUrl = this.getPreviousUrl()
        const referrerPath = referrerUrl.pathname
        const currentPath = window.location.pathname

        if (referrerPath && referrerPath !== currentPath && !String(referrerUrl).includes("clear")) {
            this.clearSelectedAttendees()
        }

        this.applyStoredSelections()
    }

    getPreviousUrl() {
        const history = JSON.parse(this.wrapperTarget.dataset.inviteesReferrerHistory);
        return history.length > 1 ? new URL(history[history.length - 2]) : null
    }

    handleSearch () {
        setTimeout(() => {
            this.applyStoredSelections()
        }, 2000)
    }

    toggleGroup(event) {
        const groupName = event.target.dataset.level
        const isChecked = event.target.checked
        this.checkboxTargets.forEach(checkbox => {
            if (checkbox.dataset.singlelevel === groupName && !checkbox.disabled) {
                checkbox.checked = isChecked
                this.updateSelectedAttendees(checkbox)
            }
        })
    }

    toggleAll() {
        const isChecked = this.selectAllTarget.checked
        this.groupCheckboxTargets.forEach(checkbox => {
            checkbox.checked = isChecked
        })
        this.checkboxTargets.forEach(checkbox => {
            if (!checkbox.disabled) {
                checkbox.checked = isChecked
                this.updateSelectedAttendees(checkbox)
            }
        })
    }

    toggleCheckbox(event) {
        this.updateSelectedAttendees(event.target)
    }

    updateSelectedAttendees(checkbox) {
        let selectedAttendees = JSON.parse(localStorage.getItem('selectedAttendees')) || {}
        if (checkbox.checked) {
            selectedAttendees[checkbox.value] = true
        } else {
            delete selectedAttendees[checkbox.value]
        }
        localStorage.setItem('selectedAttendees', JSON.stringify(selectedAttendees))
    }

    applyStoredSelections() {
        let selectedAttendees = JSON.parse(localStorage.getItem('selectedAttendees')) || {}
        this.checkboxTargets.forEach(checkbox => {
            if (selectedAttendees[checkbox.value] && !checkbox.disabled) {
                checkbox.checked = true
            }
        })
    }

    clearSelectedAttendees() {
        localStorage.removeItem('selectedAttendees')
    }

    submitForm(event) {
        event.preventDefault()

        // Remove any existing hidden inputs for invitees
        this.element.querySelectorAll('input[name="invitees[]"][type="hidden"]').forEach(el => el.remove())

        // Add hidden inputs for all selected attendees from localStorage
        const selectedAttendees = JSON.parse(localStorage.getItem('selectedAttendees')) || {}
        Object.keys(selectedAttendees).forEach(attendeeId => {
            const hiddenInput = document.createElement('input')
            hiddenInput.type = 'hidden'
            hiddenInput.name = 'invitees[]'
            hiddenInput.value = attendeeId
            this.formTarget.appendChild(hiddenInput)
        })

        this.formTarget.submit()
        this.clearSelectedAttendees()
    }

}