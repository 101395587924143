export const addSkillsToProfile = () => {
  const companySkills = document.querySelectorAll(".company-skills");
  const companySkillsinput = document.querySelectorAll(".company-skills-input");
  const skillBox = document.querySelectorAll(".skillBox");
  const skills = document.querySelectorAll(".specific-skills");
  const skillsinput = document.querySelectorAll(".specific-skills-input");
  
  //   regular skills blue border behaviour
  skills.forEach((skill) => {
    skillsinput.forEach((skillInput) => {
      skill.addEventListener('click', () => {
        if (skill.dataset.container === skillInput.dataset.skill) {
          skillInput.checked = !skillInput.checked;
          if (skill.classList.contains('single-skill-select')) {
            document.querySelectorAll('.specific-skills[data-field="'+skill.dataset.field+'"]').forEach((skillField) => {
              if (skillField.dataset.container != skill.dataset.container) {
                skillField.classList.remove('specific-skills-clicked');
              }
            })
          }
          skill.classList.toggle('specific-skills-clicked');
        }
      })
    }) 
  })

  // free text skills
  skillBox.forEach((skill) => {
    let skillContainer = skill.querySelector('.specific-skills')
    skillContainer.addEventListener('click', () => {
      let checkbox = skill.querySelector('.specific-skills-input')
      let input = skill.querySelector('.skills-value')
      
      if (checkbox.dataset.freetext) {
        if (!checkbox.checked) {
          input.classList.add('input-hidden');
        } else {
          input.classList.remove('input-hidden');
        }
      }
    })
  }) 
  
  //   company skills
  companySkills.forEach((skill) => {
    companySkillsinput.forEach((skillInput) => {
      skill.addEventListener('click', () => {
       if (skill.dataset.companycontainer === skillInput.dataset.companyskill) {
         skillInput.checked = !skillInput.checked;
          skill.classList.toggle('company-skills-clicked');
        }
      })
    }) 
  })
    
  function checkedSkills() {
     skills.forEach((skill) => {
      skillsinput.forEach((skillInput) => {
      if (skill.dataset.container === skillInput.dataset.skill) {
           if (skillInput.checked) {
            skill.classList.add('specific-skills-clicked'); 
           }
         }
      }) 
    })
  }  
    
  function companyCheckedSkills() {
     companySkills.forEach((skill) => {
      companySkillsinput.forEach((skillInput) => {
      if (skill.dataset.companycontainer === skillInput.dataset.companyskill) {
           if (skillInput.checked) {
            skill.classList.add('company-skills-clicked'); 
           }
         }
      }) 
    })
  }  
   
  checkedSkills();
  companyCheckedSkills();
}


