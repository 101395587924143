import $ from 'jquery';

export const addToCalendar = () => {
  const calendarIcons = document.querySelectorAll('.calendar-icon')
  
  calendarIcons.forEach((icon) => {
    icon.addEventListener('click', () => {
      $.ajax({
        type:"POST",
        url: "/en/actions/calendar",
        dataType:"json",
        data: {calendar: icon.dataset.calendar, event_id: icon.dataset.eventid }
      });
    });
  });
}