// video upload widget, works on ids:
// called by upload_video helper
export const uploadVideo = () => {
  const input = document.getElementById('video-upload');
  
  if (input) {
    const maxLengthSeconds = input.dataset.maxLength // time limit validation
    const urlTarget = input.dataset.urlTarget
    
    input.addEventListener('change', () => {
      document.getElementById("video-information").innerHTML = ''
      var video = document.createElement('video');
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        var duration = this.duration
        if (duration <= maxLengthSeconds) {
          if (document.getElementById('video-upload-wrapper').parentElement.className == 'field_with_errors') {
            input.parentElement.closest('form').ClientSideValidations.removeError(jQuery('#video-upload-wrapper'), 'video is too long')
          }
        	document.getElementById(urlTarget).readOnly = true
        	uploadFile(input, urlTarget);
        } else {
          if (document.getElementById('video-upload-wrapper').parentElement.className != 'field_with_errors') {
            input.parentElement.closest('form').ClientSideValidations.addError(jQuery('#video-upload-wrapper'), 'video is too long')
          }
          document.querySelector('.field_with_errors #video-upload-wrapper+label.message').innerHTML = 'video is too long'
        }
      };
      preloadVideo(input, video, urlTarget)
    });
  }
}

const preloadVideo = (input, video, urlTarget) => {
    video.preload = 'metadata';
    if (input.files.length > 0) {
      video.src = URL.createObjectURL(input.files[0])
    } else {
      document.getElementById(urlTarget).readOnly = false
      input.parentElement.closest('form').ClientSideValidations.removeError(jQuery('#video-upload-wrapper'), 'video is too long')
      document.getElementById("video-information").innerHTML = ''
    }
}
  
const uploadFile = (input, urlTarget) => {
  document.getElementById("video-information").innerHTML = '<label for="file" class="position-relative start-0">File progress:  </label><progress id="file-progress" max="100" value="0"></progress>'
  var progressbar = document.getElementById("file-progress")
  const uploader = new VideoUploader({
    file: input.files[0],
    uploadToken: input.dataset.uploadToken,
    chunkSize: 1024*1024*100, // 100MB
    retries: 10
  });
  uploader.onProgress((event) => {
    var totalPercentComplete = Math.round(event.uploadedBytes / event.totalBytes * 100);
    progressbar.value = totalPercentComplete;
  })
  uploader.upload()
    .then((video) => {
      var playerUrl = video.assets.player;
      document.getElementById(urlTarget).value = playerUrl
      document.getElementById("video-information").querySelector('label').innerHTML = "all uploaded! Preview the video <a href=\'" + playerUrl +"\' target=\'_blank\'>here</a>" ;
    }).catch(error => {
      if (document.getElementById('video-upload-wrapper').parentElement.className != 'field_with_errors') {
        input.parentElement.closest('form').ClientSideValidations.addError(jQuery('#video-upload-wrapper'), 'something went wrong')
      }
      document.querySelector('.field_with_errors #video-upload-wrapper+label.message').innerHTML = 'something went wrong'
    });
}
