import { Application } from "@hotwired/stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers";
import "controllers"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
const context = require.context("./", true, /\.js$/);
application.load(definitionsFromContext(context));

export { application }
