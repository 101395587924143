import {Calendar} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';

const initializeAgenda = () => {
    const agendaEl = document.querySelector('#agenda')
    console.log('agendaEl', agendaEl)

    // Convert events to FullCalendar events
    let eventList = [];
    let events = JSON.parse(agendaEl.getAttribute('data-events'));
    let scroll_time = agendaEl.getAttribute('data-scroll-to');
    let locale = agendaEl.getAttribute('data-locale');
    events.forEach((event) => {
        event.locale = locale;
        eventList.push(eventToAgendaEvent(event));
    });

    const agenda = new Calendar(agendaEl, {
        plugins: [interactionPlugin, timeGrid, dayGrid],
        initialView: 'timeGridDay',
        headerToolbar: {
            left: 'prev',
            center: 'title',
            right: 'next'
        },
        nowIndicator: true,
        views: {
            day: {
                titleFormat: {
                    month: 'short', day: 'numeric', weekday: 'long'
                },
            },
        },
        slotMinTime: '06:00',
        slotMaxTime: '23:00',
        slotDuration: '00:15:00',
        slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        },
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        },
        scrollTime: scroll_time,
        allDaySlot: true,
        events: [...eventList]
//        events: 'https://fullcalendar.io/api/demo-feeds/events.json'
  });

  agenda.render();

}

//setTimeout(initializeAgenda, 4000)
document.addEventListener("dashboard_tile_agenda", initializeAgenda);

const eventToAgendaEvent = event => ({
    id: event.email_group_id,
    title: event.title,
    start: event.starts_at,
    end: event.finishes_at,
    color: event.color,
    url: "/"+event.locale+"/event/"+event.email_group_id
});
