class BookableResourceForEvent {
    constructor() {
        this.resourceElement = document.getElementById("bookable-resources-event-select");
        this.outputElement = document.getElementById("bookable-resources-event-output");
        this.initializeEventListeners();
    }

    initializeEventListeners() {
        this.resourceElement.addEventListener("change", this.fetchAvailableSlots.bind(this));
        this.resourceElement.addEventListener("change", this.adjustCapacity.bind(this));
    }

    adjustCapacity() {
        const resourceId = this.resourceElement.value;
        const maxAttendees = document.getElementById("attendees-maximum");
        const capacityOutput = document.getElementById("bookable-resources-event-capacity");
        const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
        const url = `/bookable_resources/${resourceId}/check_capacity`;

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-CSRF-Token": csrfToken
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('There was a network error.');
                }
                return response.json();
            })
            .then(data => {
                console.log("data");
                console.log(data);
                if(data.capacity) {
                    maxAttendees.value = data.capacity;
                    capacityOutput.innerHTML = `Based on the selected room capacity`;
                }
            })
            .catch(error => {
                console.log(`Sorry, an error occurred while fetching capacity of this room.`);
                console.log(error);
            });
    }

    fetchAvailableSlots() {
        const resourceId = this.resourceElement.value;
        const eventId = this.resourceElement.dataset.eventId;
        const selectedDate = document.querySelector("#starts_at_date");
        const startTimeHour = document.querySelector("#relationship_action_starts_at_4i");
        const endTimeHour = document.querySelector("#relationship_action_finishes_at_4i");
        const startTimeMinute = document.querySelector("#relationship_action_starts_at_5i");
        const endTimeMinute = document.querySelector("#relationship_action_finishes_at_5i");
        const startTime = `${startTimeHour.value}:${startTimeMinute.value}`;
        const endTime = `${endTimeHour.value}:${endTimeMinute.value}`;
        const date = new Date(selectedDate.value);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;

        if (resourceId && selectedDate) {
            [selectedDate, startTimeHour, startTimeMinute, endTimeHour, endTimeMinute].forEach((input) => {
                if (!input.dataset.listenerAttached) {
                    input.addEventListener('change', this.fetchAvailableSlots.bind(this));
                    input.dataset.listenerAttached = "true";
                }
            });
        }

        if (resourceId && selectedDate) {
            const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
            const url = `/bookable_resources/${resourceId}/check_available_slots?date=${selectedDate.value}&start_time=${startTime}&end_time=${endTime}&event_id=${eventId}`;

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-CSRF-Token": csrfToken
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('There was a network error.');
                    }
                    return response.json();
                })
                .then(data => {
                    let message = data.slots.length > 0
                        ? `Available slots for this resource on ${formattedDate}: ${data.slots}`
                        : `No available slots for this resource on ${formattedDate}`;

                    this.outputElement.innerHTML = message;
                    this.outputElement.style.color = data.color;
                })
                .catch(error => {
                    this.outputElement.innerHTML = `Sorry, an error occurred while fetching available slots.`;
                });
        }
    }
}

// Initialize the class after the DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
    if (document.getElementById("bookable-resources-event-select")) {
        new BookableResourceForEvent();
    }
});
