// app/javascript/controllers/tabs_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["tab"];

    changeTab(event) {
        event.preventDefault();

        // Remove active class from all tabs
        this.tabTargets.forEach(tab => {
            tab.classList.remove("active");
        });

        // Add active class to the clicked tab's parent <li> element
        const clickedTab = event.currentTarget.closest('[data-tabs-target="tab"]');
        if (clickedTab) {
            clickedTab.classList.add("active");
        }
    }
}