export const participantFavouriteFunction = () => {
  const cardParticipantList = document.querySelectorAll('.border-favourite');
  const triggerStarList = document.querySelectorAll('.participant-favourite-link');

  cardParticipantList.forEach((participant) => {
    triggerStarList.forEach((star) => {
      star.addEventListener('click', () => {
        if (star.dataset.star === participant.dataset.participant) {
          participant.classList.toggle('toggle-star');
        }
      })
    })
  })
}
