export const flashesMsg = () => {
  const fleshCard = document.getElementById('flash_notifications');
  if (fleshCard) {
    setTimeout(() => { fleshCard.style.visibility = 'hidden'; }, 5000);
  }
  
  let labelMsgError = document.querySelectorAll('label.message');
    labelMsgError.forEach((msg) => {
      setTimeout(() => { msg.style.visibility = 'hidden'; }, 5000);
    })
 };


