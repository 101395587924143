import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "hint",
        "select",
        "loading",
    ];

    async loadOptions(event) {
        this.updateContent(event.target, {button_label: "loading"});
        const directoryId = event.target.dataset.directoryId;
        const participantId = event.target.dataset.participantId;
        const currentUserId = event.target.dataset.currentUserId;
        const response = await fetch(
            `/member_directories/${directoryId}/connect/${currentUserId}/${participantId}`,
            {
                headers: {
                    Accept: "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            },
        );
        const data = await response.json();
        this.updateContent(event.target, data);
    }

    updateContent(eventTarget, data) {
        let btn = eventTarget;

        if (data.button_label === "loading") {
            this.hideOtherTargets("loading");
        } else {
            this.loadingTarget.classList.add("d-none");

            if (data.can_be_requested.length > 0) {
                this.selectTarget.classList.remove("d-none");
                this.selectTarget.classList.remove("invisible");
                const select = this.selectTarget.querySelector("select");
                select.innerHTML =
                    `<option value="" selected>Select an option and connect with ${data.participant_first_name}</option>`;
                data.can_be_requested.forEach((involvement) => {
                    const option = document.createElement("option");
                    option.value = involvement.path;
                    option.textContent = involvement.label;
                    option.dataset.track = involvement.track_email_group_id;
                    option.dataset.adminMatchApproval = involvement.admin_match_approval;
                    option.dataset.matchAndStart = involvement.match_and_live;
                    select.appendChild(option);
                });
            }

            let hint = "";

            if (data.awaiting_approval.length > 0) {
                hint += `You have pending requests with ${data.participant_first_name}:<br /><ul>`;
                data.awaiting_approval.forEach((relationship) => {
                    hint += `<li data-relationship='${relationship.relationship_email_group_id}'>${relationship.label_relationship} as ${relationship.label_role} in ${relationship.track_title}</li>`;
                });
                hint += "</ul>";

                if (data.can_be_requested.length === 0) {
                    this.modifyButton(btn, "Request Pending");
                }
            }

            if (data.started.length > 0 || data.not_started.length > 0) {
                hint += `Existing relationships between you and ${data.participant_first_name}:<ul>`;
                data.started.forEach((relationship) => {
                    hint += `<li data-relationship='${relationship.relationship_email_group_id}'>${relationship.label_relationship} as ${relationship.label_role} in ${relationship.track_title}</li>`;
                });
                data.not_started.forEach((relationship) => {
                    hint += `<li data-relationship='${relationship.relationship_email_group_id}'>${relationship.label_relationship} as ${relationship.label_role} in ${relationship.track_title} (not started)</li>`;
                });
                hint += "</ul>";

                if (data.can_be_requested.length === 0 && data.awaiting_approval.length === 0) {
                   this.modifyButton(btn, "Connected");
                }
            }

            if (data.started.length === 0 && data.not_started.length === 0 && data.awaiting_approval.length === 0 && data.can_be_requested.length === 0) {
                hint += "At the moment, there is no available option to connect with this user. This could be because:<br /><ul>";
                hint += "<li>you are not participating in the same programs of this user</li>";
                hint += "<li>you are participating in the same program, but you your role does not allow you to connect with this user</li>";
                hint += "<li>this user is not currently active or approved in the programs you are participating</li>";
                hint += "<li>some programs are set to have a limit of connections between users</li>";
                hint += "</ul>";
                this.modifyButton(btn, "Unavailable");
            }

            if (hint) {
                this.hintTarget.querySelector("p").innerHTML = hint;
                this.hintTarget.classList.remove("d-none");
                this.hintTarget.classList.remove("invisible");
            }

        }
    }

    modifyButton(btn, label, keepEnabled = false) {
        btn.textContent = label;

        if (keepEnabled) {
            btn.classList.add("btn-primary");
            btn.classList.remove("btn-light");
        } else {
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-light");
        }
    }

    hideOtherTargets(currentTargetName) {
        const targetNames = Object.values(this.constructor.targets);

        targetNames.forEach((targetName) => {
            let target = this[`${targetName}Target`];

            let currentTarget = this[`${currentTargetName}Target`];
            if (targetName !== currentTargetName) {
                target.classList.add("d-none");
            } else {
                currentTarget.classList.remove("invisible");
                currentTarget.classList.remove("d-none");
            }
        });
    }

    requestMatch(event) {
        const path = event.target.value;
        if (path) {
            window.location.href = path;
        }
    }
}
