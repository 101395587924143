export const sidebarForm = () => {
  if (document.getElementById('link_form')) {

    const updateFields = ( el ) => {
      var val = el.value + 'Options';
      var appendTo = document.getElementById('link_resource_id');
      var externalLinkField = document.getElementById('external_link_field')
      let formLabel = document.getElementById('resource_label')
      let urlField = document.getElementById('url_field');

      Array.from(appendTo.getElementsByTagName('option')).forEach(c => appendTo.removeChild(c));
      externalLinkField.style.display = 'block';
      if (el.value === 'external_link') {
        externalLinkField.style.display = '';
        formLabel.innerHTML = el.value;
      }
      if (!models[val] || !Array.isArray(models[val])) {
        appendTo.style.display = 'none';
        return;
      }

      formLabel.innerHTML = el.value.charAt(0).toUpperCase() + el.value.substring(1).toLowerCase().replace(/_/,' ')
      models[val].forEach(m => {
        externalLinkField.style.display = 'none';
        let opt = document.createElement('option');
        opt.textContent = m[0];
        opt.value = m[1];
        appendTo.appendChild(opt);
      });
      externalLinkField.style.display = 'none';
      appendTo.style.display = '';
    }

    updateFields(document.getElementById('link_resource_type'))
    document.getElementById('link_resource_id').value = document.getElementById('selected_resource_id').innerText
    document.getElementById('link_resource_type').addEventListener('change', e => {
      var el = e.target;
      updateFields(el)
    });

    document.getElementById('link_icon').addEventListener('keyup', e => {
      let iconDiv = document.getElementById('icon')
      const regstr = /(?<prefix>fa[sdlr])? ?(?:fa-)?(?<name>[a-z0-9\-]*)/ //icon api does not like prefixes
      var match = e.target.value.match(regstr)
      var prefix = match.groups.prefix
      var iconName = match.groups.name
      var icon = FontAwesome.icon({prefix: prefix || 'far', iconName: iconName }, {transform: {size: 36} })
      iconDiv.innerHTML = icon ? icon.html : 'no icon found'
    });
  };
};
