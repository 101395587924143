import { Controller } from "stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    const dropzoneElement = this.element;
    if (!dropzoneElement) {
      return;
    }

    const modelValue = dropzoneElement.getAttribute('data-model');
    const idValue = dropzoneElement.getAttribute('data-id');

    let images;
    try {
      images = JSON.parse(dropzoneElement.getAttribute('data-dropzone-images'));
    } catch (error) {
      return;
    }

    this.initializeDropzone(modelValue, idValue);
    this.populateImages(images);
  }

  initializeDropzone(modelValue, idValue) {
    let csrfToken = null;
    if (process.env.NODE_ENV !== 'test') {
      const csrfElement = document.querySelector('meta[name="csrf-token"]');
      if (csrfElement) {
        csrfToken = csrfElement.getAttribute('content');
      }
    }

    const dropzoneConfig = {
      addRemoveLinks: true,
      chunking: false,
      forceChunking: false,
      parallelUploads: 30,
      uploadMultiple: true,
      url: `/${modelValue}/${idValue}/add_images`
    };

    if (csrfToken) {
      dropzoneConfig.headers = {
        "X-CSRF-Token": csrfToken
      };
    }

    this.dropzone = new Dropzone(this.element, dropzoneConfig);

    this.dropzone.on("addedfile", file => this.handleAddedFile(file));
    this.dropzone.on("removedfile", file => this.handleRemovedFile(file));
  }

  populateImages(images) {
    if (!images || !Array.isArray(images)) {
      return;
    }

    images.forEach((image, i) => {
      let mockFile = { name: image.filename, size: image.size };
      this.dropzone.displayExistingFile(mockFile, image.dataURL);

      const dzImageElement = document.querySelectorAll('.dz-image img')[i];
      if (dzImageElement) {
        dzImageElement.src = image.dataURL.split("?")[0];
      }
    });
  }

  handleAddedFile(file) {
    let images = [];
    images.push({ url: "", name: file.name, size: file.size });
  }

  handleRemovedFile(file) {
    const csrfToken = document.getElementsByName("csrf-token")[0].getAttribute("content");
 
    const dropzoneElement = this.element;
    const modelValue = dropzoneElement.getAttribute('data-model');
    const idValue = dropzoneElement.getAttribute('data-id');
    const removeUrl = `/${modelValue}/${idValue}/remove_images`;

    fetch(removeUrl, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        ...(csrfToken ? { "X-CSRF-Token": csrfToken } : {})
      },
      body: JSON.stringify({ name: file.name })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      })
      .catch(error => {});
  }
}
