// Removes images instantly and replaces the preview with a default one (only frontend!).
// You should call it like this (you can change the name of the images):
// <div onClick="removeImages(banner, photo)" data-banner-default="https://..." data-photo-default="https://..."></div>
// The image previews should have an id `upload_${imageName}_0_file_preview` (eg. upload_banner_0_file_preview, upload_photo_0_file_preview).

export const imgRemover = () => {
    const removeImages = (...props) => {
        props?.forEach(prop => {
            document.getElementById(`upload_${prop}_0_file_preview`).src = event.target.dataset[prop + 'Default'];
        });
    };
    window.removeImages = removeImages;
};