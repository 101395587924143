// Use this function to disable a button when it is clicked and change its text to something like Saving...
// This is not necessary right now because we are using Rails UJS (disable_with), but this will be useful if we decide to remove Rails UJS and move to Turbo.

export const disableButton = () => {
    document.addEventListener('click', function (event) {
        const button = event.target.closest('button[data-loading-text]');
        if (button) {
            const form = button.closest('form');
            if (form) {
                form.addEventListener('submit', function () {
                    button.innerHTML = button.dataset.loadingText;
                    button.disabled = true;
                });
            }
        }
    });
}