export const persistMessages = () => {
  const messageBoxes = document.querySelectorAll('.message_body');
  messageBoxes.forEach((messageBox) => {
    var input = messageBox.firstElementChild
    var submit = messageBox.nextElementSibling
    if (input) {
      input.value = window.localStorage.getItem('message_body.' + input.dataset.emailId);
      input.addEventListener('input',  (event) => {
    		var message = input.value
    		if (message) {
    		  window.localStorage.setItem(('message_body.' + input.dataset.emailId), message);
    		}
    		else {
    		  window.localStorage.removeItem('message_body.' + input.dataset.emailId)
    		}
    	});
    	submit.addEventListener('click', (event) => {
    	   window.localStorage.removeItem('message_body.' + input.dataset.emailId)
    	})
    }
  });
};