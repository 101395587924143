import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';
import listView from '@fullcalendar/list';

const initializeAllBookingsCalendar = () => {
    const allBookingsCalendarEl = document.getElementById('fullcalendarAllBookings');

    let events = [];
    let bookings = JSON.parse(allBookingsCalendarEl.getAttribute('data-bookings'));

    bookings.forEach((booking) => {
        events.push(bookingToEvent(booking));
    });

    const calendar = new Calendar(allBookingsCalendarEl, {
        plugins: [interactionPlugin, timeGrid, dayGrid, listView],
        initialView: 'timeGridThreeDay',
        views: {
            timeGridThreeDay: {
                type: 'timeGrid',
                duration: { days: 3 }
            }
        },
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        eventClick: info => {
            window.location.href = `/bookable_resources/${info.event.extendedProps.bookableId}`;
        },
        nowIndicator: true,
        slotMinTime: '07:00',
        slotMaxTime: '23:00',
        slotDuration: '00:15:00',
        slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        },
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        },
        allDaySlot: false,
        selectOverlap: false,
        events: [...events]
    });

    calendar.render();
}

// Initialize calendar only on the bookable_resources#show page
const allBookingsCalendarInitializer = () => {
    const path = window.location.pathname;

    if (path.includes('bookable_resources') && document.querySelector("#fullcalendarAllBookings")) {
        initializeAllBookingsCalendar();
    }
}
document.addEventListener("turbolinks:load", allBookingsCalendarInitializer);

const bookingToEvent = booking => ({
    id: booking.email_group_id,
    title: `${booking.title} - ${booking.bookable_name}`,
    bookableId: booking.bookable_id,
    start: booking.start_datetime,
    end: booking.end_datetime,
    color: booking.color
});