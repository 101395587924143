// import { Controller } from "stimulus";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bar" ]
  connect() {
    console.log("Hello, Stimulus!");
    if (this.hasBarTarget) {
      this.animateProgress()
    }
  }

  animateProgress() {
    const bar = this.barTarget
    const width = bar.style.width
    bar.style.width = '0%'
    setTimeout(() => {
      bar.style.width = width
    }, 50)
  }
}
