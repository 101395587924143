import $ from 'jquery';
import sortable from 'jquery-ui';

export const sorting = () => {
  if (document.getElementById('results')) {
    $("#results").sortable({
      update: function(e, ui) {
        $.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    });
  };
};