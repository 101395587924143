import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "clear"];

  connect() {
    // This method is called automatically when the controller is connected to the DOM
    this.updateClearButtonVisibility();
  }

  handleInput() {
    // Called on 'input' event
    this.updateClearButtonVisibility();
  }

  clear() {
    // Called on clicking the clear icon
    this.inputTarget.value = '';
    this.updateClearButtonVisibility();
  }

  submitOnEnter(event) {
    if (event.keyCode === 13) { // 13 is the Enter key
      this.submitSearch();
    }
  }

  submitSearch() {
    console.log("Search submitted:", this.inputTarget.value);
    // Add your search submission logic
  }

  updateClearButtonVisibility() {
    // Show or hide the clear button based on the presence of text in the input
    const shouldShow = this.inputTarget.value.length > 0;
    this.clearTarget.style.display = shouldShow ? 'flex' : 'none';
  }
}